import React, { useCallback } from 'react'

import Layout from 'components/layout'
import HeroComponent from 'components/hero'
import CallToAction from 'components/callToAction'
import ValoresSection from 'components/valores'
import Seo from 'components/seo'

import { graphql } from 'gatsby'

const NuestraEmpresaPage = ({ data, location }) => {
  const {
    sanityAppereance: {
      tabsAppereance: { header, footer },
    },
    sanityValoresPage: {
      tabsValores: { seo, Call_to_Action, Hero, Valores },
    },
  } = data

  const setCallToActionBtn = useCallback(() => {
    console.log('click btn contáctanos')
  }, [])

  return (
    <Layout header={header} footer={footer}>
      {seo?.seo?.focus_keyword && seo?.author && seo?.seo?.focus_synonyms && (
        <Seo data={seo} />
      )}
      {Hero?.enable && (
        <HeroComponent
          navbar={Hero?.logoEmpresaHeader?.image?.asset?.url}
          navbarAlt={Hero?.logoEmpresaHeader?.alt}
          imageBackground={Hero?.image.image.asset.url}
          title={Hero?.title}
          subTitle={Hero?.description}
          btnText={Hero?.cta?.text}
          btnCallToAction={setCallToActionBtn}
          btnEnable={Hero?.cta?.enable}
          btnLink={Hero?.cta?.link}
          location={location.pathname}
        />
      )}
      {Valores?.enable && (
        <ValoresSection
          title={Valores?.encabezadoMain?.text}
          description={Valores?.encabezadoMain?._rawDescription}
          enableEncabezado={Valores?.encabezadoMain?.enable}
          rawValores={Valores?._rawValores}
          objectValores={Valores?.Valores}
        />
      )}
      {Call_to_Action?.enable && (
        <CallToAction
          title={Call_to_Action.title}
          paragraph={Call_to_Action.description}
          btnText={Call_to_Action.cta.text}
          btnCallToAction={setCallToActionBtn}
          btnEnable={Call_to_Action.cta.enable}
        />
      )}
    </Layout>
  )
}

export const ValoresQuery = graphql`
  query ValoresQuery {
    sanityValoresPage(slug: { current: { eq: "valores-page" } }) {
      tabsValores {
        seo {
          author
          _key
          seo {
            seo_title
            meta_description
            focus_synonyms
            focus_keyword
            _type
            _key
          }
          ogImage {
            asset {
              url
            }
          }
        }
        Call_to_Action {
          description
          enable
          title
          cta {
            enable
            link
            text
          }
        }
        Hero {
          cta {
            enable
            link
            text
          }
          description
          enable
          title
          image {
            image {
              asset {
                url
              }
            }
            alt
          }
          logoEmpresaHeader {
            image {
              asset {
                url
              }
            }
            alt
          }
        }
        Valores {
          _rawValores
          enable
          encabezadoMain {
            _rawDescription
            text
            enable
          }
          Valores {
            _rawDescription
            image {
              alt
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    }
    sanityAppereance(slug: { current: { eq: "apariencia" } }) {
      tabsAppereance {
        footer {
          footerTitle
          footerParagrah
          enableFooter
          direccion
          departamento
          correo3
          correo2
          correo1
          logoEmpresaFooter {
            alt
            image {
              asset {
                url
              }
            }
          }
        }
        header {
          correo
          enableHeader
          linkFacebook
          linkInstagram
          linkLinkedin
          linkTwitter
          messenger
          telefono
        }
      }
    }
  }
`

export default NuestraEmpresaPage
